import { useAppContext } from "../../contexts/AppContext";
import { useMenuExpanded } from "../../hooks/useMenuExpanded";
import { useOrganisation } from "./hooks/useOrganisation";

export const OrgSwitcher = () => {
  const { profile } = useAppContext();
  const { organisations, selectedOrganisation, setOrganisation } =
    useOrganisation(profile);

  const { ref, isMenuExpanded, setMenuExpanded } = useMenuExpanded(false);
  return (
    <>
      {organisations.length > 1 ? (
        <>
          <div className="relative inline-block " ref={ref}>
            <button
              className="relative z-10 flex items-center p-2 text-sm border border-transparent rounded-md focus:ring-opacity-40 focus:ring-blue-400 focus:ring text-white bg-gray-800 focus:outline-none"
              onClick={() => setMenuExpanded(!isMenuExpanded)}
            >
              <span className="mx-1">{selectedOrganisation?.name}</span>
              <svg
                className="w-5 h-5 mx-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>

            <div
              className={`absolute right-0 z-20 w-56 py-2 mt-2 overflow-hidden rounded-md shadow-xl bg-white ${
                isMenuExpanded ? "" : "hidden"
              }`}
            >
              {organisations.map((org) => (
                <button
                  key={org.orgId}
                  className={`block px-4 py-3 text-sm capitalize transition-colors duration-200 transform text-gray-600 hover:bg-gray-300 w-full text-left ${
                    org.orgId === selectedOrganisation?.orgId
                      ? "font-bold disabled"
                      : ""
                  }`}
                  onClick={() => {
                    setOrganisation(org.orgId);
                    setMenuExpanded(false);
                  }}
                >
                  {org.name}
                </button>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <span className="mx-1 text-white">{selectedOrganisation?.name}</span>
        </>
      )}
    </>
  );
};
