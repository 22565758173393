import React, { ComponentType } from "react";
import { RevealIcon } from "./SvgLibrary/RevealIcon";
import { getInitials } from "../../utils/users";
import { User } from "@auth0/auth0-react";
import { useAbility } from "../casl/hooks/useAbility";

export const UserAvatar: ComponentType<{ user?: User; showIcon: boolean }> = ({
  user,
  showIcon
}) => {
  const { undermapsAbility } = useAbility();
  if (!user) {
    return <></>;
  }
  return (
    <div className="w-10 h-10 sm:w-12 sm:h-12 relative flex justify-center items-center rounded-full bg-gray-500 text-base sm:text-xl text-white uppercase">
      {undermapsAbility.can("internal") && showIcon && (
        <RevealIcon className="w-4 h-4 absolute top-0 right-0 bg-white/100 border-2 rounded-sm border-white" />
      )}
      {getInitials(user?.name)}
    </div>
  );
};
