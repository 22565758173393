import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Profile } from "../../../api/types/profile";

const ORG_ID_PARAM = "orgId";

export const useOrganisation = (profile: Profile | undefined) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const organisations = useMemo(() => {
    if (!profile) {
      return [];
    }
    return profile.organisations || [];
  }, [profile]);

  const selectedOrganisation = useMemo(() => {
    if (!profile) {
      return;
    }

    const orgParam = searchParams.get(ORG_ID_PARAM);
    if (orgParam) {
      const orgId = Number.parseInt(orgParam);
      return profile.organisations.find((o) => o.orgId === orgId);
    }
    return profile.organisations.length > 0
      ? profile.organisations[0]
      : undefined;
  }, [profile, searchParams]);

  const setOrganisation = useCallback(
    (orgId: number) => {
      setSearchParams(
        (previousParams) => {
          previousParams.set("orgId", orgId.toString());
          return previousParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  return {
    organisations,
    selectedOrganisation,
    setOrganisation
  };
};
