import { FC } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

export const MapShell: FC = () => (
  <div className="fixed flex flex-col h-full w-full">
    <Navbar />
    <hr />
    <Outlet />
  </div>
);
