import { useAuth0 } from "@auth0/auth0-react";
import { appRoutes } from "../../utils/routes";
import { Link } from "react-router-dom";
import { OrgSwitcher } from "./OrgSwitcher";
import { useMenuExpanded } from "../../hooks/useMenuExpanded";
import { UserAvatar } from "../common/UserAvatar";
import { CaslVisible } from "../casl/CaslVisible";

const Navbar = () => {
  const { ref, isMenuExpanded, setMenuExpanded } = useMenuExpanded(false);
  const auth0 = useAuth0();

  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = auth0;

  return (
    <div>
      <nav className="bg-gray-800">
        <div className="sm:container mx-auto px-6">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden"></div>
            <div className="flex-1 flex items-stretch justify-start">
              <Link
                to={{
                  pathname: "/"
                }}
              >
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src="/reveal-icon.svg"
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src="/reveal-brand.svg"
                    alt="Workflow"
                  />
                </div>
              </Link>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              {isAuthenticated && (
                <>
                  <OrgSwitcher />
                  <div className="ml-3 relative">
                    <div>
                      <button
                        type="button"
                        className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        id="user-menu-button"
                        onClick={() => setMenuExpanded(!isMenuExpanded)}
                      >
                        <span className="sr-only">Open user menu</span>
                        <UserAvatar user={user} showIcon={true} />
                      </button>
                    </div>

                    {isMenuExpanded && (
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                        role="menu"
                        ref={ref}
                      >
                        <CaslVisible type="Undermaps" action="admin">
                          <div className="bg-gray-100 px-4 pt-2">
                            <p className="text-xs text-gray-700">
                              Admin links:
                            </p>
                            <Link
                              className="block px-4 py-2 text-sm text-gray-700"
                              to={{
                                pathname: appRoutes.ADMIN
                              }}
                              onClick={() => setMenuExpanded(!isMenuExpanded)}
                            >
                              Admin
                            </Link>
                            <Link
                              className="block px-4 py-2 text-sm text-gray-700"
                              to={{
                                pathname: appRoutes.DATA_MODEL
                              }}
                              onClick={() => setMenuExpanded(!isMenuExpanded)}
                            >
                              Data Model
                            </Link>
                            <Link
                              className="block px-4 py-2 text-sm text-gray-700"
                              to={{
                                pathname: appRoutes.MAP_2D
                              }}
                              onClick={() => setMenuExpanded(!isMenuExpanded)}
                            >
                              2D View
                            </Link>
                          </div>
                        </CaslVisible>
                        <CaslVisible type="Undermaps" action="internal">
                          <Link
                            className="block px-4 py-2 text-sm text-gray-700"
                            to={{
                              pathname: appRoutes.COLLECT
                            }}
                            onClick={() => setMenuExpanded(!isMenuExpanded)}
                          >
                            Collect
                          </Link>
                        </CaslVisible>
                        <a
                          href={`mailto:twinsupport@reveal.nz?body=%0D%0A%0D%0A%0D%0ATroubleshooting details:%0D%0AUsername:%20${
                            user?.email
                          }%0D%0ATime:%20${new Date().toLocaleString()}%0D%0AUrl:%20${escape(
                            window.location.href
                          )}`}
                          className="block px-4 py-2 text-sm text-gray-700"
                        >
                          Contact support
                        </a>
                        <button
                          id="sign-out"
                          onClick={() =>
                            logout({ returnTo: window.location.origin })
                          }
                          className="block px-4 py-2 text-sm text-gray-700"
                        >
                          Sign out
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}

              {!isAuthenticated && !isLoading && (
                <div className="ml-3 relative">
                  <button
                    id="sign-in"
                    onClick={() => loginWithRedirect()}
                    className="h-10 px-5 m-2 text-gray-100 transition-colors duration-150 bg-gray-700 rounded-lg focus:shadow-outline hover:bg-gray-500"
                  >
                    Sign in
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
