import { useState, useEffect, useRef, useCallback } from "react";

export const useMenuExpanded = (
  initialIsVisible: boolean,
  handleClickOutside: boolean = true,
  onMenuClose?: () => void
) => {
  const [isMenuExpanded, setMenuExpanded] = useState(initialIsVisible);
  const ref = useRef<any>(null);

  const clickedOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenuExpanded(false);
        if (onMenuClose) onMenuClose();
      }
    },
    [onMenuClose]
  );

  useEffect(() => {
    if (!handleClickOutside) return;
    document.addEventListener("click", clickedOutside, true);
    return () => {
      document.removeEventListener("click", clickedOutside, true);
    };
  }, [clickedOutside, handleClickOutside]);

  return { ref, isMenuExpanded, setMenuExpanded };
};
