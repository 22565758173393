import { useCallback, useState } from "react";

// Known keys used in Collect & Projects
type LocalStorageKey =
  | "twinTokenDetails"
  | "poleHeight"
  | "gpsPhaseCentre"
  | "gpsReceiver"
  | "gpsHeightOffsetExpires"
  | "userPreferredPage"
  | "collectProjectId"
  | "wipOperations"
  | "ntripServiceConfig"
  | "FeatureDisplayMinimised"
  | "showDepthPins"
  | "layerFilterData"
  | "serviceFilterData"
  | "showTolerances"
  | "transformerProjectMappings"
  | "recentProjectLayer"
  | "doNotAutoAcceptLocation"
  | "disableGPSSnapTo";

const getItem = (k: LocalStorageKey) => localStorage.getItem(k) ?? undefined;
const setItem = (k: LocalStorageKey, v?: string | null | undefined) => {
  if (v === undefined || v === null) {
    localStorage.removeItem(k);
  } else {
    localStorage.setItem(k, v as string);
  }
};
const removeItem = (k: LocalStorageKey) => localStorage.removeItem(k);
const clear = () => localStorage.clear();

const serialize = <K>(data: K): string | null => {
  if (!data) {
    return null;
  }
  return JSON.stringify(data);
};

type StoredData = Record<string, any>;
const deserialize = <I extends StoredData>(
  input: string | null | undefined
): I => {
  if (input) {
    try {
      const result = JSON.parse(input);
      // hack until all keys are unified
      if (result.projectToWip) {
        return result.projectToWip;
      }
      if (result.projectToLayerFilter) {
        return result.projectToLayerFilter;
      }
      // ignore anything unexpected
      if (result) {
        return result;
      }
    } catch (e) {
      console.error(`Error parsing MultiProjectWip: ${e}`);
    }
  }
  return {} as I;
};

/**
 * A simple wrapper around localStorage.
 */
export const useLocalStore = () => {
  return {
    getItem,
    setItem,
    serialize,
    deserialize,
    removeItem,
    clear
  };
};

export const useLocalState = (
  key: LocalStorageKey,
  watchValue?: boolean
): [string | undefined, (value: string | undefined) => void] => {
  const { getItem, setItem } = useLocalStore();
  const [item, setItemState] = useState<string | undefined>(getItem(key));

  if (watchValue) {
    const updatedItem = getItem(key);
    if (updatedItem !== item) {
      setItemState(updatedItem);
    }
  }

  return [
    item,
    useCallback(
      (item: string | undefined) => {
        setItem(key, item);
        setItemState(item);
      },
      [key, setItem]
    )
  ];
};
