import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import "./App.css";
import { appRoutes } from "./utils/routes";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import {
  FC,
  lazy,
  ReactNode,
  Suspense,
  useEffect,
  useMemo,
  useState
} from "react";
import { AppContext } from "./contexts/AppContext";
import { modes } from "./utils/modes";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthorisationRules, useProfile } from "./api/adminApi";
import DataModel from "./components/admin/DataModel";
import LogRocket from "logrocket";
import { Loader } from "./components/Loader";
import { MapShell } from "./components/layout/MapShell";
import { useUserPreferredPage } from "./hooks/useUserPreferredPage";
import { config } from "./config";
import AuthCheck from "./components/common/AuthCheck/AuthCheck";
import { ContactContextProvider } from "./contexts/ContactContext";
import { useExternalScripts } from "./hooks/useExternalScripts";
import { LoggedInRedirect } from "./components/common/LoggedInRedirect/LoggedInRedirect";
import { RevealState } from "./api/revealFetch";
import { Profile } from "./api/types/profile";
import Error from "./components/Error";
import { useNewRelicCustomAttribute } from "./hooks/useNewRelic";
const Map = lazy(() => import("./components/Map"));
const Map2D = lazy(() => import("./components/Map2D/Map2D"));
const AdminPage = lazy(() => import("./components/admin/AdminPage/AdminPage"));
const MapEdit = lazy(() => import("./components/collect/editor/MapEditor"));
const AerialPicker = lazy(
  () => import("./components/collect/aerialPicker/AerialPicker")
);
const Transformer = lazy(
  () => import("./components/collect/transformer/Transformer")
);
const CollectPage = lazy(
  () => import("./components/collect/collect/CollectPage/CollectPage")
);

const Map3D = lazy(() => import("./components/collect/3d/ThreeDee"));

const ProjectsPage = lazy(
  () => import("./components/projects/ProjectsPage/ProjectsPage")
);
const UnderdotsPage = lazy(
  () => import("./components/underdots/UnderdotsPage/UnderdotsPage")
);
const UndermapsPage = lazy(
  () => import("./components/undermaps/UndermapsPage/UndermapsPage")
);

const HUBSPOT_PORTAL_ID = "21811062";

const Lazy: FC<{ onLoad?: () => void; children: ReactNode }> = ({
  children,
  onLoad
}) => {
  useEffect(() => {
    onLoad && onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Suspense fallback={<Loader />}>{children}</Suspense>;
};

const App = () => {
  const [mode, setMode] = useState<string>(modes.EXPLORE);
  const [searchParams] = useSearchParams();

  const { userPreferredPage, setUserPreferredPage } = useUserPreferredPage(
    appRoutes.PROJECTS,
    [
      appRoutes.COLLECT,
      appRoutes.PROJECTS,
      appRoutes.EDIT,
      appRoutes.AERIAL,
      appRoutes.TRANSFORMER
    ]
  );

  const profileAsync = useProfile();
  const authorisationRules = useAuthorisationRules();
  const profile = profileAsync.data;

  const hubSpotScriptUrl = `//js.hs-scripts.com/${HUBSPOT_PORTAL_ID}.js`;
  useExternalScripts(hubSpotScriptUrl);

  const win = window as any;
  //Add Hubspot user cookie

  useEffect(() => {
    if (profile && win.HubSpotConversations) {
      win.hsConversationSettings = {
        identificationEmail: profile?.user?.emailAddress
      };
      const _hsq = (win._hsq = win._hsq || []);
      _hsq.push([
        "identify",
        {
          email: profile?.user.emailAddress
        }
      ]);
    }
  }, [profile, win]);

  useEffect(() => {
    if (
      !profile?.user ||
      searchParams.has("disableLogRocket") ||
      (config.nodeEnv !== "production" && !searchParams.has("enableLogRocket"))
    ) {
      return;
    }
    LogRocket.init("pyjnny/revealtwin", {
      mergeIframes: true
    });
    LogRocket.identify(profile.user.userId.toString(), {
      userId: profile.user.userId,
      email: profile.user.emailAddress,
      organisations: JSON.stringify(profile.organisations.map((o) => o.orgId)),
      userRole: profile.user.userRole
    });
    // Ignore searchParams update here to avoid re-initialising log rocket on param change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileAsync.data]);

  useNewRelicCustomAttribute("userEmail", profile?.user.emailAddress);

  return (
    <AppContext.Provider
      value={{
        mode,
        setMode,
        profile,
        profileAsync,
        authorisationRules
      }}
    >
      <ContactContextProvider>
        <Routes>
          <Route
            path="/"
            element={
              <HomePageRedirect
                userPreferredPage={userPreferredPage}
                profile={profileAsync}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN + "/*"}
            element={
              <AuthCheck
                redirectUnauthorisedUserTo={appRoutes.PROJECTS}
                type="Undermaps"
                action="admin"
              >
                <Lazy>
                  <AdminPage />
                </Lazy>
              </AuthCheck>
            }
          />
          <Route path="/" element={<MapShell />}>
            <Route
              path={appRoutes.MAP}
              element={
                <AuthCheck
                  redirectUnauthorisedUserTo={appRoutes.PROJECTS}
                  type="Undermaps"
                  action="standard"
                >
                  <Lazy onLoad={() => setUserPreferredPage(appRoutes.PROJECTS)}>
                    <Map />
                  </Lazy>
                </AuthCheck>
              }
            />
            <Route
              path={appRoutes.MAP_2D}
              element={
                <AuthCheck
                  redirectUnauthorisedUserTo={appRoutes.PROJECTS}
                  type="Undermaps"
                  action="standard"
                >
                  <Lazy>
                    <Map2D />
                  </Lazy>
                </AuthCheck>
              }
            />

            <Route
              path={appRoutes.DATA_MODEL}
              element={
                <AuthCheck
                  redirectUnauthorisedUserTo={appRoutes.PROJECTS}
                  type="Undermaps"
                  action="standard"
                >
                  <DataModel />
                </AuthCheck>
              }
            />

            <Route path="/404" element={<NotFound />} />
          </Route>
          <Route
            path={appRoutes.PROJECTS + "/*"}
            element={
              <Lazy onLoad={() => setUserPreferredPage(appRoutes.PROJECTS)}>
                <ProjectsPage />
              </Lazy>
            }
          />
          <Route
            path={appRoutes.COLLECT + "/*"}
            element={
              <AuthCheck
                redirectUnauthorisedUserTo={appRoutes.PROJECTS}
                type="Application"
                action="use"
                subject="Collect"
                onLoading={<Loader />}
              >
                <Lazy onLoad={() => setUserPreferredPage(appRoutes.COLLECT)}>
                  <CollectPage />
                </Lazy>
              </AuthCheck>
            }
          />
          <Route
            path={appRoutes.EDIT + "/*"}
            element={
              <AuthCheck
                redirectUnauthorisedUserTo={appRoutes.PROJECTS}
                type="Application"
                action="use"
                subject="Edit"
                onLoading={<Loader />}
              >
                <Lazy onLoad={() => setUserPreferredPage(appRoutes.EDIT)}>
                  <MapEdit title="Map Editor" />
                </Lazy>
              </AuthCheck>
            }
          />
          <Route
            path={appRoutes.AERIAL + "/*"}
            element={
              <AuthCheck
                redirectUnauthorisedUserTo={appRoutes.PROJECTS}
                type="Application"
                action="use"
                subject="Aerial Picker"
                onLoading={<Loader />}
              >
                <Lazy onLoad={() => setUserPreferredPage(appRoutes.AERIAL)}>
                  <AerialPicker title="Aerial Picker" />
                </Lazy>
              </AuthCheck>
            }
          />
          <Route
            path={appRoutes.TRANSFORMER + "/*"}
            element={
              <AuthCheck
                redirectUnauthorisedUserTo={appRoutes.PROJECTS}
                type="Application"
                action="use"
                subject="Transformer"
                onLoading={<Loader />}
              >
                <Lazy
                  onLoad={() => setUserPreferredPage(appRoutes.TRANSFORMER)}
                >
                  <Transformer title="Transformer" />
                </Lazy>
              </AuthCheck>
            }
          />
          <Route
            path={appRoutes.COLLECT_3D + "/*"}
            element={
              <AuthCheck
                redirectUnauthorisedUserTo={appRoutes.PROJECTS}
                type="Undermaps"
                action="standard"
                onLoading={<Loader />}
              >
                <Lazy>
                  <Map3D title={"3D View"} />
                </Lazy>
              </AuthCheck>
            }
          />
          <Route
            path={appRoutes.UNDERDOTS + "/*"}
            element={
              <LoggedInRedirect redirectLoggedInUserTo="/projects/new-underdots">
                <Lazy>
                  <UnderdotsPage />
                </Lazy>
              </LoggedInRedirect>
            }
          />
          <Route
            path="*"
            element={
              <Lazy>
                <UndermapsPage />
              </Lazy>
            }
          />
        </Routes>
      </ContactContextProvider>
    </AppContext.Provider>
  );
};

const HomePageRedirect: FC<{
  userPreferredPage: string;
  profile: RevealState<Profile>;
}> = ({ userPreferredPage, profile }) => {
  const { isAuthenticated } = useAuth0();

  const redirectUserTo = useMemo(() => {
    if (!profile.data) return false;

    const userPrefersUndermaps =
      profile.data?.organisations.length === 0 ||
      profile.data?.organisations.some((org) => org.prefersUndermaps);

    return userPrefersUndermaps ? appRoutes.UNDERMAPS : userPreferredPage;
  }, [profile, userPreferredPage]);

  if (!isAuthenticated)
    return (
      <div className="fixed flex flex-col h-full w-full">
        <Home />
      </div>
    );

  return (
    <>
      {redirectUserTo && <Navigate to={redirectUserTo} replace />}
      {profile.loading && <Loader />}
      {!profile.loading && profile.error && <Error />}
    </>
  );
};

export default App;
